import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { Challenges } from '../../editor/types/Experiments';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { challengeDataProviderPropsMap } from '../../contexts/ChallengeDataProvider/challengeDataProviderPropsMap';
import { inviteLinkPropsMap } from '../../contexts/InviteLink/inviteLinkPropsMap';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;

  const { isEditor } = flowAPI.environment;

  return {
    async pageReady() {
      try {
        const props = await providersPropsMap({
          biSettings: {
            preventAutomaticallySendChallengeId: isEditor,
            screenName: ScreenNames.ThankYouPage,
          },
          flowAPI,
          providers: [
            challengeDataProviderPropsMap,
            inviteLinkPropsMap,
            userProviderPropsMap,
          ],
        });
        const initialProps = {
          ...props,
        };

        const enabledSeoMetatags = flowAPI.experiments.enabled(
          Challenges.enableSeoMetatagsThankYou,
        );

        if (enabledSeoMetatags) {
          renderSeoMetatagsPage(
            flowAPI,
            initialProps?.challengeData?.challenge,
            'THANK_YOU_PAGE',
          );
        }

        setProps(initialProps);
      } catch (err) {
        console.error(err);
      }
    },
  };
};

export default createController;
